<template>
  <div class="container">
    <div class="row align-items-center my-2">
      <div class="col">
        <h3 class="m-0">Not Found</h3>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="card-text">
          We cannot find what you are looking for. Please try again.
        </div>
      </div>
    </div>
  </div>
</template>
