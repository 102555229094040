<template>
  <nav
    id="header-nav"
    class="navbar navbar-expand fixed-top d-flex border-bottom py-0"
    :class="{ 'test-mode': testMode }"
  >
    <div class="container">
      <ul class="nav navbar-nav flex-fill justify-content-start">
        <li class="nav-item">
          <img id="logo" src="@/app/asset/logo.png" />
        </li>
      </ul>
      <ul
        class="nav navbar-nav flex-fill justify-content-end align-item-center"
      >
        <li class="nav-item p-1 me-1">
          <span v-if="scannerStatus !== 0" class="offline">
            <span class="fa-stack">
              <i class="fas fa-barcode-read"></i>
              <i class="fas fa-slash fa-stack-1x"></i>
            </span>
          </span>
          <span v-if="scannerStatus === 0" class="online">
            <i class="fas fa-barcode-read"></i> {{ scannerBattery }}
          </span>
        </li>
        <li class="nav-item p-1 me-1 d-none">
          <span v-if="!online" class="offline">
            <i class="fas fa-wifi-slash"></i>
          </span>
          <span v-if="online" class="online">
            <i class="fas fa-wifi"></i>
          </span>
        </li>
        <li class="nav-item">
          <button
            class="btn p-1 border-0 h-100"
            type="button"
            aria-controls="offcanvas"
            @click="offcanvasOpen = true"
          >
            {{ initials }} <i class="fas fa-user"></i>
          </button>
        </li>
      </ul>
    </div>
  </nav>
  <Teleport to="#offcanvas">
    <AccountComponent
      :offcanvasOpen="offcanvasOpen"
      @toggleOffcanvas="offcanvasOpen = false"
    />
  </Teleport>
</template>

<script setup>
import { ref } from 'vue';
import AccountComponent from '@/app/component/AccountComponent.vue';
import { useOnline } from '@vueuse/core';
import { useConfig } from '@/setting/composable/useConfig';
import { useMsal } from '@/msal/composable/useMsal';
import { useScanner } from '@/scanner/composable/useScanner';

// Reactive properties
const offcanvasOpen = ref(false);

// Composables
const online = useOnline();
const { testMode } = useConfig();
const { initials } = useMsal();
const {
  status: scannerStatus,
  battery: scannerBattery,
} = useScanner();
</script>

<style>
nav {
  background-color: #fff;
}
nav img {
  max-width: 100%;
}
#header-nav {
  height: 44px;
}
#logo {
  width: 162px;
}
.test-mode {
  background-color: #f8d7da;
}
.online {
  color: #81bc00;
}
.offline {
  color: #d87c85;
}
.fa-stack {
  height: auto;
  width: auto;
}
</style>
