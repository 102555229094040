'use strict';

import { db } from '@/db/idb.js';

export const itemRepository = {
  getAllByCustomer,
  add,
  getAllByCustomerItem,
  getLocalCount,
};

async function getAllByCustomer(customer) {
  // Get all items by customer from local db
  return await (
    await db
  ).getAllFromIndex('customerItem', 'customerNumber', customer);
}

async function getAllByCustomerItem(customer, customerItemNumber) {
  // Get all items by customer from local db
  return await (
    await db
  ).getAllFromIndex('customerItem', 'customerItemNumber', [
    customer,
    customerItemNumber,
  ]);
}

async function getLocalCount() {
  return await (await db).count('customerItem');
}

async function add(items) {
  const tx = (await db).transaction('customerItem', 'readwrite');

  // Create a list of promises that can be all run together
  const promises = [];
  for (const item of items) {
    // Put each item into the store
    promises.push(tx.store.put(item));
  }
  promises.push(tx.done);

  await Promise.all(promises);

  return items;
}
