'use strict';

import OrderView from '@/order/view/OrderView.vue';

export const orderRoutes = [
  {
    path: '/orders',
    component: OrderView,
    children: [
      {
        path: '',
        name: 'OrderList',
        component: () => import('@/order/view/OrderListView.vue'),
      },
      {
        path: '/orders/add/:customerNumber?',
        name: 'OrderAdd',
        props: true,
        component: () => import('@/order/view/OrderSaveView.vue'),
      },
      {
        path: '/orders/:orderId',
        name: 'OrderDetail',
        props: true,
        component: () => import('@/order/view/OrderDetailView.vue'),
      },
      {
        path: '/orders/:orderId/edit',
        name: 'OrderEdit',
        props: true,
        component: () => import('@/order/view/OrderSaveView.vue'),
      },
      {
        path: '/orders/:orderId/delete',
        name: 'OrderDelete',
        props: true,
        component: () => import('@/order/view/OrderDeleteView.vue'),
      },
      {
        path: '/orders/:orderId/sync',
        name: 'OrderSync',
        props: true,
        component: () => import('@/order/view/OrderSyncView.vue'),
      },
      {
        path: '/orders/sync-all',
        name: 'OrderSyncAll',
        props: true,
        component: () => import('@/order/view/OrderSyncAllView.vue'),
      },
    ],
  },
];
