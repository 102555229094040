'use strict';

import { db } from '@/db/idb.js';

export const keyval = {
  get,
  set,
  clear,
  delete: _delete, // Remap the reserved keyword
};

async function get(key) {
  return await (await db).get('keyval', key);
}

async function set(key, value) {
  return await (await db).put('keyval', value, key);
}

async function clear() {
  return await (await db).clear('keyval');
}

// Delete is a reserved work so prefix with underscore
async function _delete(key) {
  return await (await db).delete('keyval', key);
}
