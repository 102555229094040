'use strict';

import { ref } from 'vue';
import { msalInstance } from '@/msal/service/msal';
import { InteractionRequiredAuthError } from '@azure/msal-browser';

const email = ref('');
const fullname = ref('');
const initials = ref('');

/**
 * useMsal - MSAL composable function
 * @returns {Object} loggedInUser - simple object of user information
 */
export function useMsal() {
  const handleResponse = async () => {
    try {
      const response = await msalInstance.handleRedirectPromise();

      // There is an open session
      if (response !== null) {
        //console.log(response);
        msalInstance.setActiveAccount(response.account);

        setLoggedInUser();
        return true;
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Login to MSAL
   * @returns {boolean}
   */
  const login = async () => {
    try {
      // Not logged in
      const account = msalInstance.getAllAccounts()[0];
      if (!account) {
        const loginRequest = {
          scopes: ['User.Read'],
        };

        await msalInstance.loginRedirect(loginRequest);
        return false;
      }

      // Logged in
      setLoggedInUser();
      return true;
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * Logout of MSAL
   * @returns {void}
   */
  const logout = async () => {
    clearUser();
    const account = msalInstance.getAllAccounts()[0];

    // Use the logged in account
    const logoutRequest = {
      account: account,
    };

    // Log out of msal
    return await msalInstance.logoutRedirect(logoutRequest);
  };

  /**
   * Get updated tokens from MSAL
   * @param {Object} account - MSAL account
   * @returns {Promise}
   */
  const getToken = async () => {
    const account = msalInstance.getAllAccounts()[0];

    const tokenRequest = {
      scopes: ['api://3b1fdbc8-d7e6-49c5-be7a-e2424705a5cd/access_as_user'],
      account: account,
    };

    try {
      const token = await msalInstance.acquireTokenSilent(tokenRequest);
      return token.accessToken;
    } catch (e) {
      if (e instanceof InteractionRequiredAuthError) {
        console.log('Acquiring token using redirect');
        await msalInstance.acquireTokenRedirect(tokenRequest);
      }
    }
  };

  /**
   * Set formatted user object for view purposes
   * @returns {Object}
   */
  const setLoggedInUser = () => {
    const account = msalInstance.getAllAccounts()[0];
    // If not logged in return null
    if (!account) return clearUser();

    // Get users initials
    fullname.value = account.name;
    email.value = account.username;
    const name = account.name;
    const nameSplit = name.split(' ');
    initials.value = nameSplit[0].charAt(0) + nameSplit[1].charAt(0);

    return;
  };

  const clearUser = () => {
    email.value = '';
    initials.value = '';
    fullname.value = '';

    return;
  };

  //setLoggedInUser();

  return {
    account: msalInstance.getAllAccounts()[0],
    handleResponse,
    login,
    logout,
    getToken,
    setLoggedInUser,
    email,
    fullname,
    initials,
  };
}
