<template>
  <component :is="layout" />

  <UpdateAppComponent />
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import UpdateAppComponent from '@/app/component/UpdateAppComponent.vue';

// Composables
const router = useRouter();

// Reactive properties
const layout = ref('DefaultLayout');

// Set layout on each request
router.beforeEach((to) => {
  if (to.meta.layout) {
    layout.value = to.meta.layout;
  } else {
    layout.value = 'DefaultLayout';
  }
});
</script>
