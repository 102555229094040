'use strict';

import { inject } from 'vue'

/**
 * @typedef {Object} Scanner
 * @property {Number} status - Status of scanner connection
 * @property {String} data - Decoded data from scanner
 * @property {String} battery - Percentage value of the battery
 * @property {Function} warning - Send long haptic feedback to scanner
 * @property {Function} confirm - Send short haptic feedback to scanner
 */

/**
 * Composable function that extracts global via provide / inject
 * @returns {Scanner}
 */
export const useScanner = () => {
  const scanner = inject('scanner');

  return scanner;
};
