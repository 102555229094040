<template>
  <div
    ref="modalRef"
    class="modal fade"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
    v-if="modalOpen"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <slot name="header"></slot>
        </div>
        <div class="modal-body">
          <slot name="body"></slot>
        </div>
        <div class="modal-footer" v-if="$slots.footer">
          <slot name="footer"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue';
import { Modal } from 'bootstrap';

// Reactive properties
const modalOpen = ref(false);

// Template properties
const modalRef = ref(null);

// Local properties
let modal = null;

const open = async () => {
  modalOpen.value = true;
  // Wait for re-render
  await nextTick();

  modal = new Modal(modalRef.value);
  modal.show();

  modalRef.value.addEventListener('hidden.bs.modal', () => {
    modalOpen.value = false;
  });
};

const close = () => {
  modal.hide();
  modalOpen.value = false;
};

// Expose functions
defineExpose({
  open,
  close,
});
</script>
