import { openDB } from 'idb/with-async-ittr';

// Open store by: (await db).<function>()
export const db = openDB('thruway', 2, {
  upgrade: (db, oldVersion) => {
    switch (oldVersion) {
      case 0: {
        console.log('Creating database version 1');
        // Create customer store
        db.createObjectStore('customer', {
          keyPath: 'customerNumber',
        });

        // Create cusomter address store
        const customerAddress = db.createObjectStore('customerAddress', {
          keyPath: ['customerNumber', 'addressCode'],
        });
        customerAddress.createIndex('customerNumber', 'customerNumber');
        customerAddress.createIndex('customerAddressCode', [
          'customerNumber',
          'addressCode',
        ]);

        // Create item store
        const item = db.createObjectStore('customerItem', {
          keyPath: ['customerNumber', 'customerItemNumber', 'itemNumber'],
        });
        item.createIndex('customerNumber', 'customerNumber');
        item.createIndex('customerItemNumber', [
          'customerNumber',
          'customerItemNumber',
        ]);
        item.createIndex('itemNumber', 'itemNumber');

        // Create order store
        const order = db.createObjectStore('customerOrder', {
          keyPath: 'id',
          autoIncrement: true,
        });
        order.createIndex('userOrder', 'user');
        order.createIndex('userCustomerNumber', [
          'user',
          'customerNumber'
        ]);

        // Create cusomter keyval store
        db.createObjectStore('keyval');
      }
      case 1: {
        console.log('Creating database version 2');
        // Create cusomter address store
        const customerAddressItem = db.createObjectStore('customerAddressItem', {
          keyPath: ['customerNumber', 'addressCode', 'customerItemNumber', 'itemNumber'],
        });
        customerAddressItem.createIndex('customerNumberAddressCode', [
          'customerNumber',
          'addressCode'
        ]);

        break;
      }
      default: {
        console.error('Unknown db version');
      }
    }
  },
});
