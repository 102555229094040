'use strict';

import { db } from '@/db/idb.js';

export const addressRepository = {
  getAllByCustomer,
  getById,
  getByAddressCode,
  add,
  getLocalCount,
};

async function getAllByCustomer(customer) {
  // Get all customers from local db
  return await (
    await db
  ).getAllFromIndex('customerAddress', 'customerNumber', customer);

  // let cursor = await (await db).transaction("customerAddress").store.openCursor();
  // //let cursor = await (await db).transaction("customerAddress").store.index("addressCode").openCursor();
  // let addesses = [];

  // while (cursor) {
  //   addesses.push(cursor.value);
  //   cursor = await cursor.continue();
  // }
  // return addesses;
}

async function getLocalCount() {
  return await (await db).count('customerAddress');
}

async function getById(id) {
  return await (await db).get('customerAddress', id);
}

async function getByAddressCode(customerNumber, addressCode) {
  return await (
    await db
  ).getFromIndex('customerAddress', 'customerAddressCode', [
    customerNumber,
    addressCode,
  ]);
}

async function add(addresses) {
  const tx = (await db).transaction('customerAddress', 'readwrite');

  // Create a list of promises that can be all run together
  const promises = [];
  for (const address of addresses) {
    // Put each item into the store
    promises.push(tx.store.put(address));
  }
  promises.push(tx.done);

  await Promise.all(promises);

  return addresses;
}
