'use strict';

import { ref } from 'vue';

const testMode = ref(false);
const apiUrl = ref('https://api.thruwayinc.com/v1.1');

/**
 * useConfig - Config composable function
 * @returns {Object} config - simple object of config
 */
export function useConfig() {
  const setTestMode = async (value) => {
    // const url = process.env.NODE_ENV === 'production'
    //   ? 'https://api.thruwayinc.com/v1.1'
    //   : 'https://api-sandbox.thruwayinc.com/v1.1'

    //await keyval.set('testMode', value);
    testMode.value = value;
    apiUrl.value = value
      ? 'https://api-sandbox.thruwayinc.com/v1.1'
      : 'https://api.thruwayinc.com/v1.1';
  };

  return {
    setTestMode,
    testMode,
    apiUrl,
  };
}
