import { createRouter, createWebHistory, createMemoryHistory } from 'vue-router';
import { msalRoutes } from '@/msal/route/msalRoute';
import { customerRoutes } from '@/customer/customer/route/customerRoute';
import { orderRoutes } from '@/order/route/orderRoute';
import NotFound from '@/app/view/NotFoundView.vue';
//import { useOnline } from '@vueuse/core';
import { useMsal } from '@/msal/composable/useMsal';

const defaultRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/app/view/HomeView.vue'),
  },
  {
    path: '/:pathMatch(.*)*',
    component: NotFound,
  },
];

let allRoutes = [];
allRoutes = allRoutes.concat(
  defaultRoutes,
  msalRoutes,
  customerRoutes,
  orderRoutes
);

const routes = allRoutes;

const router = createRouter({
  //history: createWebHistory(import.meta.env.BASE_URL),
  history: createMemoryHistory(),
  routes,
});


/**
 * Verify the user is logged in on every page load, also handles the MSAL redirect promoise
 */
router.beforeEach(async (to) => {
  // Redirect to login page if not logged in and trying to access a restricted page
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const { account, handleResponse, setLoggedInUser } = useMsal();

  // Is there an open MSAL session, if so the account object will be set.
  await handleResponse();

  // User is not logged in and trying to access protected page.
  if (authRequired && !account) {
    //console.log('Not logged in');
    return { name: 'Login' };
  }

  // User is logged in
  setLoggedInUser();
});

/**
 * Get token after each page load, allows auth redirect to come back to page the request originated from
 */
// router.afterEach(async () => {
//   const online = useOnline();
//   const { getToken } = useMsal();
//   // If online, get token
//   if (online) {
//     await getToken();
//   }
// });

export default router;
