'use strict';

import { useConfig } from '@/setting/composable/useConfig';
import { db } from '@/db/idb.js';
import { requestOptions } from '@/_helpers/requestOptions.js';
import { handleResponse } from '@/_helpers/handleResponse.js';

const { apiUrl } = useConfig();

export const customerRepository = {
  getAll,
  getByCustomerNumber,
  add,
  getLocalCount,
  getAllFromApi,
};

async function getAll() {
  // Get all customers from local db
  let cursor = await (await db).transaction('customer').store.openCursor();
  //let cursor = await (await db).transaction("customer").store.index("customerNumber").openCursor();
  let customers = [];

  while (cursor) {
    customers.push(cursor.value);
    cursor = await cursor.continue();
  }
  return customers;
}

async function getAllFromApi() {
  return fetch(`${apiUrl.value}/customers`, await requestOptions.get()).then(
    handleResponse
  );
}

async function getLocalCount() {
  const count = await (await db).count('customer');
  return count;
}

async function getByCustomerNumber(customerNumber) {
  const customer = await (await db).get('customer', customerNumber);

  return customer;
}

async function add(customers) {
  const tx = (await db).transaction('customer', 'readwrite');

  // Create a list of promises that can be all run together
  const promises = [];
  for (const customer of customers) {
    // Put each item into the store
    promises.push(tx.store.put(customer));
  }
  promises.push(tx.done);

  await Promise.all(promises);

  return customers;
}
