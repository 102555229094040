<template>
  <Teleport to="#modal">
    <ModalComponent ref="modalRef">
      <template #header>
        <h5 class="modal-title">
          <i class="fas fa-sync"></i>
          Update Available!
        </h5>
      </template>
      <template #body>
        <button class="btn btn-danger w-100" @click="updateServiceWorker()">
          Update App Now
        </button>
      </template>
    </ModalComponent>
  </Teleport>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useRegisterSW } from 'virtual:pwa-register/vue'
import ModalComponent from '@/app/component/ModalComponent.vue';

// Reactive properties
const modalRef = ref(null);

// Composables
const { needRefresh, updateServiceWorker } = useRegisterSW();

/**
 * Watch for when service worker has an avaialble update then open modal
 * @returns {void}
 */
watch(
  () => needRefresh.value,
  (value) => {
    if (value) modalRef.value.open();
  }
)

</script>
