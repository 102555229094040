'use strict';

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);

    if (!response.ok) {
      if ([401, 403].indexOf(response.status) !== -1) {
        // Handle unauthenticated or unauthorized
        return;
      }

      const error = (data && data.message) || response.statusText;
      //console.log('Api Error', error);
      return Promise.reject(error);
    }

    return data;
  });
}
