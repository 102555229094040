<template>
  <footer id="footer">
    <div class="row fixed-bottom border-top bg-white">
      <div class="col">
        <RouterLink
          :to="{ name: 'Home' }"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <button
            type="button"
            class="btn p-0 w-100 nav-link"
            role="link"
            :href="href"
            @click="navigate"
            :class="[
              isActive && 'router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <i class="fas fa-home"></i>
            <div class="link-text">Home</div>
          </button>
        </RouterLink>
      </div>
      <div class="col">
        <RouterLink
          :to="{ name: 'OrderList' }"
          v-slot="{ href, navigate, isActive, isExactActive }"
          custom
        >
          <button
            type="button"
            class="btn p-0 w-100 nav-link"
            role="link"
            :href="href"
            @click="navigate"
            :class="[
              isActive && 'router-link-active',
              isExactActive && 'router-link-exact-active',
            ]"
          >
            <i class="fas fa-shopping-cart"></i>
            <div class="link-text">Orders</div>
          </button>
        </RouterLink>
      </div>
    </div>
  </footer>
</template>

<script setup></script>

<style scoped>
footer {
  text-align: center;
}
.navbar {
  margin: 0;
  padding: 0;
}
button {
  border-top: 3px solid transparent;
  border-radius: 0;
}
button {
  font-size: 1.25rem;
  color: #666;
}
.link-text {
  font-size: 0.75rem;
}

.router-link-active {
  color: #8dc73f;
  border-top: 3px solid #8dc73f;
}
.router-link-active {
  color: #8dc73f;
}
</style>
